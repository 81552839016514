<template>
  <div class="controls-container">
    <control-item v-for="item in this.controlKeys" :key-control-information="item" :key="item.key" />
  </div>
</template>

<script>
import ControlItem from './ControlItem';

export default {
  name: 'ControlsContainer',
  components: {
    ControlItem
  },
  computed: {
    controlKeys() {
      return [{
        key: 'Ctrl',
        title: 'CTRL Key',
        description: 'Hold down the CTRL key to pan vertically or horizontally while mousing.'
      }, {
        key: 'Shift',
        title: 'SHIFT Key',
        description: 'Hold down the SHIFT key to pan while mousing.'
      }];
    }
  }
};
</script>

<style scoped>
</style>